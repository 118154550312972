var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.data.length > 0)?_c('div',{staticClass:"container-fluid py-4 bg-us"},[_c('h1',{staticClass:"col-12 text-center my-1 text-yellow text-capitalize"},[_vm._v(" Servergarden magazin ")]),_c('div',{staticClass:"container py-4"},[_c('div',{staticClass:"row"},_vm._l((this.data),function(item,index){return _c('div',{key:index,staticClass:"col-lg-4"},[_c('router-link',{attrs:{"to":_vm.prefix +
            '/' +
            _vm.createDate(item.date, 'YYYY/MM/DD') +
            '/' +
            item.link}},[(item.image !== null)?_c('img',{staticClass:"img-fluid",staticStyle:{"height":"200px","object-fit":"cover"},attrs:{"src":item.image,"alt":item.title}}):_vm._e()]),_c('div',{staticClass:"clearfix py-3"},[_c('div',{staticClass:"col col float-start text-lightblue list-date"},[_vm._v(" "+_vm._s(_vm.createDate(item.date, "MMM DD. YYYY"))+" ")])]),_c('div',{staticClass:"text-white fw-bold fs-5 article-list-h"},[_c('router-link',{staticClass:"text-white text-decoration-none",attrs:{"to":_vm.prefix +
            '/' +
            _vm.createDate(item.date, 'YYYY/MM/DD') +
            '/' +
            item.link}},[_vm._v(" "+_vm._s(item.title)+" ")])],1),_c('div',{staticClass:"text-lightblue fs-7 pt-2 article-list-h",domProps:{"innerHTML":_vm._s(item.lead)}}),_c('router-link',{staticClass:"text-decoration-none px-0 py-0 text-yellow list-date tovabbBtn",attrs:{"to":_vm.prefix +
            '/' +
            _vm.createDate(item.date, 'YYYY/MM/DD') +
            '/' +
            item.link}},[_vm._v("Tovább "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrow-right']}})],1)],1)}),0)]),_c('div',{staticClass:"container px-0 text-center my-1"},[_c('router-link',{staticClass:"btn bg-yellow fw-medium",attrs:{"to":_vm.prefix,"type":"button"}},[_vm._v("Még több hír")])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }