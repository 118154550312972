<!--
Home Articles
-->
<template>
  <div class="container-fluid py-4 bg-us" v-if="this.data.length > 0">
    <h1 class="col-12 text-center my-1 text-yellow text-capitalize">
      Servergarden magazin
    </h1>
    <div class="container py-4">
      <div class="row">
        <div class="col-lg-4" v-for="(item, index) in this.data" :key="index">
          <router-link
              :to="
              prefix +
              '/' +
              createDate(item.date, 'YYYY/MM/DD') +
              '/' +
              item.link
            "
          >
          <img
            v-if="item.image !== null"
            class="img-fluid"
            style="height: 200px; object-fit: cover"
            v-bind:src="item.image"
            v-bind:alt="item.title"
          />
          </router-link>
          <div class="clearfix py-3">
            <div class="col col float-start text-lightblue list-date">
              {{ createDate(item.date, "MMM DD. YYYY") }}
            </div>
          </div>
          <div class="text-white fw-bold fs-5 article-list-h">
            <router-link
                :to="
              prefix +
              '/' +
              createDate(item.date, 'YYYY/MM/DD') +
              '/' +
              item.link
            "
                class="text-white text-decoration-none"
            >
            {{ item.title }}
            </router-link>
          </div>
          <div
            class="text-lightblue fs-7 pt-2 article-list-h"
            v-html="item.lead"
          />
          <router-link
            :to="
              prefix +
              '/' +
              createDate(item.date, 'YYYY/MM/DD') +
              '/' +
              item.link
            "
            class="text-decoration-none px-0 py-0 text-yellow list-date tovabbBtn"
            >Tovább <font-awesome-icon :icon="['fas', 'arrow-right']"
          /></router-link>
        </div>
      </div>
    </div>

    <div class="container px-0 text-center my-1">
      <router-link :to="prefix" type="button" class="btn bg-yellow fw-medium"
        >Még több hír</router-link
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "module12",
  props: {
    data: { data: [Array] },
    prefix: { data: ["String"], required: true },
  },
  methods: {
    createDate(date, format) {
      return moment(date).format(format);
    },
  },
};
</script>

<style scoped></style>
